import * as React from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";

const ContactResult = ({ result, error }) => {
  const { t } = useTranslation();
  if (error) {
    return (
      <div className="w-3/5 lg:w-full mb-10 p-5 font-medium text-red-800 bg-white border-2 border-red-800">
        <span className="font-bold">{t("errors")}</span><br/><br/>
        {error.message}
      </div>
    );
  } else {
    if(result.responseCode === undefined){
      return (
        <div className="w-3/5 lg:w-full">
        </div>
      );
    } else {
      switch (result.responseCode) {
        case 200 :
          return (
            <div className="w-3/5 lg:w-full mb-10 font-bold text-xl">
              {t(result.response)}
            </div>
          );
        case 400:
          return (
            <div className="w-3/5 lg:w-full mb-10 p-5 font-medium text-red-800 bg-white border-2 border-red-800">
              <span className="font-bold">{t("errors")}</span><br/><br/>  
              {result.errorDescription.map((error) => (<span >{t(error)}<br/></span>))}
            </div>
          );
        default:
          return (
            <div className="w-3/5 lg:w-full mb-10 p-5 font-medium text-red-800 bg-white border-2 border-red-800">
            <span className="font-bold">{t("errors")}</span><br/><br/>  
              {t(result.errorDescription)}
            </div>
          );
      }
    }
  }
}

export default ContactResult;