import React, { useState } from 'react';
import { graphql } from 'gatsby'
import TitleAltComponent from "../components/title-alt"
import Layout from "../components/layout/layout"
import { useTranslation } from "gatsby-plugin-react-i18next";
import ContactForm from "../components/contact-form";
import ContactResult from "../components/contact-result"
import SEOComponent from "../components/seo";

const ContactPage = ({ data }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  const [result, setResult] = useState({});
  const [error, setError] = useState("");
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("menu_contact")} />
        <TitleAltComponent title={t("menu_contact")} description={siteContent.contact.description}></TitleAltComponent>
        <div className="section px-4">
          <ContactResult result={result} error={error}></ContactResult>
          <ContactForm setResult={setResult} setError={setError} siteContent={siteContent}></ContactForm>
        </div>
      </Layout>
    </main>
  )
}
export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    siteContent(language: {eq: $language}) {
        editionYear
        programYear
        contact {
          description
          mailReceiver
          sitekey
          legalNotice
        }
        language
        cookieURL
        personalDataURL
        mediaKit
        baseURL
        banner
    }
  }
`