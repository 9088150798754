import parse from "../utils/html-parser.options"
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "gatsby-plugin-react-i18next";
import { TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import SubmitButton from "./elements/SubmitButton";

const ContactForm = ({ setResult, setError, siteContent }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState(" ");
  const [checked, setChecked] = useState({ reason_partner: false, reason_info: false, reason_support: false, reason_improvments: false });
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);

  useEffect(() => {

    if (token)
      console.log(`hCaptcha Token: ${token}`);

  }, [token]);
    
  const handleSubmit = event => {
    event.preventDefault()
    submit();
  }
    
  function changeReason(event){
    setReason(t(event.target.value));
    setChecked(() => {
      return {
        reason_partner: false,
        reason_info: false,
        reason_support: false,
        reason_improvments: false,
        [event.target.value]: true
      };
    });
  }

  function submit() {    
    const jsonErrors = [];
    document.getElementById('error_captcha').style.display = "none";
    if(document.getElementById('last_name').value === ""){
      jsonErrors.push("error_last_name");
    }
    if(document.getElementById('first_name').value === ""){
      jsonErrors.push("error_first_name");
    }
    if(document.getElementById('mail').value === ""){
      jsonErrors.push("error_mail");
    }
    if(reason === " "){
      jsonErrors.push("error_reason");
    }
    if(document.getElementById('description').value === ""){
      jsonErrors.push("error_description");
    }
    if(token === null || token === ""){
      document.getElementById('error_captcha').style.display = "block";
      jsonErrors.push("error_captcha");
    }
    if(jsonErrors.length === 0){
      const data = new FormData();
      data.set("toAddress", siteContent.contact.mailReceiver);
      data.set("firstName", encodeURIComponent(document.getElementById('first_name').value));
      data.set("lastName", encodeURIComponent(document.getElementById('last_name').value));
      data.set("mail", encodeURIComponent(document.getElementById('mail').value));
      data.set("phone", encodeURIComponent(document.getElementById('phone').value === ""?" ":document.getElementById('phone').value));
      data.set("reason", encodeURIComponent(reason));
      data.set("description", encodeURIComponent(document.getElementById('description').value));
      data.set("token", token);
      fetch(siteContent.baseURL + '/o/numerique-responsable-ws/contact/send-mail/', {method: 'POST', body: data})
      .then(response => response.json())
      .then(
        (result) => {
          setResult(result);
          if(result.responseCode === 200) {
            document.getElementById('last_name').value = "";
            document.getElementById('first_name').value = "";
            document.getElementById('mail').value = "";
            document.getElementById('phone').value = "";
            setChecked(() => ({ reason_partner: false, reason_info: false, reason_support: false, reason_improvments: false }));
            document.getElementById('description').value = "";
          }
        },
        (error) => {
          setError(error);
        },
        window.scroll(0, 0)
      );
    }else{
      setResult({
        "errorDescription": jsonErrors,
        "responseCode": 400
      })
    }
    captchaRef.current.resetCaptcha();
    setToken(null);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-2 w-3/5 lg:w-full gap-10">
        <TextField id="last_name" label={t("form_last_name")} variant="standard" required/>
        <TextField id="first_name" label={t("form_first_name")} variant="standard" required/>
        <TextField id="mail" label={t("form_mail")} variant="standard" required/>
        <TextField id="phone" label={t("form_phone")} variant="standard" />
      </div>
      <div className="my-8">
        <span className="text-xl font-bold">{t("contact_reason")} *</span>
        <RadioGroup name="reason" row >
          <FormControlLabel value="reason_partner" control={<Radio required/>} label={t("reason_partner")} checked={checked.reason_partner} onClick={changeReason} />
          <FormControlLabel value="reason_info" control={<Radio />} label={t("reason_info")} checked={checked.reason_info} onClick={changeReason} />
          <FormControlLabel value="reason_support" control={<Radio />} label={t("reason_support")} checked={checked.reason_support} onClick={changeReason} />
          <FormControlLabel value="reason_improvments" control={<Radio />} label={t("reason_improvments")} checked={checked.reason_improvments} onClick={changeReason} />
        </RadioGroup>
      </div>
      <div className="w-3/5 lg:w-full">
        <TextField id="description" fullWidth label={t("form_description")} multiline minRows={5} maxRows={5} required/>
      </div><br/><br/>
      <div>
        <HCaptcha
          sitekey={siteContent.contact.sitekey}
          onVerify={setToken}
          ref={captchaRef}
        />
        <div id="error_captcha" className="hidden mt-5"><p className='p-3 font-medium text-red-800 bg-white border-2 border-red-800 max-w-sm'>{t("error_captcha")}</p></div>
      </div>
      <div className="mt-10">
        <SubmitButton >{t("form_send")}</SubmitButton>
      </div>
      <div className="mt-10">
        {parse(siteContent.contact.legalNotice)}
      </div>
    </form>
  )
}

export default ContactForm;